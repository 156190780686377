//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { rtParams } from "@/utils.js";
export default {
  name: "LangSwitch",
  data() {
    return {
      active: false,
    };
  },
  computed: {
    ...rtParams,
  },
  methods: {
    label(s) {
      switch (s) {
        case "uk":
          return "Укр";
        case "ru":
          return "Рус";
        case "en":
          return "En";
        case "de":
          return "De";
        default:
          return "undefined";
      }
    },
  },
};
