//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    linkType: {
        type: String,
        default: "link"
    },
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasIcon() {
      return this.icon != null && this.icon != "non" && this.type !== "text";
    },
  },
};
