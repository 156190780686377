//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  components: {},
  name: "SiteFooter",
  data() {
    return {
      btnPos: "top",
    };
  },
  computed: {
    ...mapGetters([
      "getLogo",
      "getFooterPhone",
      "getFooterMenu",
      "getSocialLinks",
      "getFooterInfo"
    ]),
    // ...mapGetters({
    // 	getLogo: "getLogo",
    // 	getFooterPhone: "getFooterPhone",
    // 	getFooterMenu: "getFooterMenu",
    // 	getSocialLinks: "getSocialLinks",
    // }),
    btnBot() {
      return this.btnPos == "bot";
    },
    btnTop() {
      return this.btnPos == "top";
    },
  },
  methods: {
    toup() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
