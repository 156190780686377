import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
  // locale: process.env.VUE_APP_I18N_LOCALE || 'uk',
  locale: ['de', 'uk', 'en'],
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  //   fallbackLocale: "en",
  messages: {
    uk: {
      fieldErrors: {
        email: 'Введiть email',
        required: "Обов'язкове поле",
        minLength: "Мінімальна кількість символів: {length}",
        maxLength: "Максимальна кількість символів: {length}",
        selectDate: "Вкажіть дату",
        selectRadio: "Оберіть варіант",
        selectTime: "Вкажіть час"
      },
      send: "Надіслати",
      footer: {
        subscribeSuccess: 'Підписку успішно оформлено! &#128522;'
      },
      error: {
        t1: "Ой! Сторінку не знайдено",
        t2: "Ця сторінка не існує, оскільки її було видалено! Ми пропонуємо вам повернутися додому",
        t3: "Назад додому",
      },
      form: {
        t1: "Ваш запит успішно надіслано, наші менеджери зв’яжуться з вам найближчим часом!",
        btn: "Добре",
        blogT1: "Підписатися на наші новини",
        blogT2: "Тепер ви підписані",
      },
      contacts: "Контакти",
      pcs: "шт"
    },
    en: {
      fieldErrors: {
        email: 'Enter Email',
        required: "Required field",
        minLength: "Minimum number of characters: {length}",
        maxLength: "Maximum number of characters: {length}",
        selectDate: "Please enter a date",
        selectRadio: "Choose an option",
        selectTime: "Specify the time"
      },
      send: "Надіслати",
      footer: {
        subscribeSuccess: 'The subscription has been successfully completed! &#128522;'
      },
      error: {
        t1: "Ooops! Page Not Found",
        t2: "This page doesn`t exist os was removed! We suggest you back to home",
        t3: "Back to Home",
      },
      form: {
        t1: "Your request has been successfully sent, our managers will contact you shortly!",
        btn: "Ok",
        blogT1: "Subscribe to our news",
        blogT2: "Now you are subscribed",
      },
      contacts: "Contacts",
      pcs: "pcs"
    },
    de: {
        fieldErrors: {
          email: 'Email eingeben',
          required: "Pflichtfeld",
          minLength: "Mindestanzahl an Zeichen: {length}",
          maxLength: "Maximale Anzahl von Zeichen: {length}",
          selectDate: "Bitte geben Sie ein Datum ein",
          selectRadio: "Wähle eine Option",
          selectTime: "Geben Sie die Uhrzeit an"
        },
        send: "Tsontakte",
        footer: {
          subscribeSuccess: 'Das Abonnement wurde erfolgreich abgeschlossen! &#128522;'
        },
        error: {
          t1: "Hoppla! Seite nicht gefunden",
          t2: "Diese Seite existiert nicht oder wurde entfernt! Wir empfehlen Ihnen, nach Hause zurückzukehren",
          t3: "Zurück nach Hause",
        },
        form: {
          t1: "Ihre Anfrage wurde erfolgreich gesendet, unsere Manager werden sich in Kürze mit Ihnen in Verbindung setzen!",
          btn: "Ok",
          blogT1: "Abonnieren Sie unsere Neuigkeiten",
          blogT2: "Jetzt sind Sie abonniert",
        },
        contacts: "Kontakte",
        pcs: "stck"
      },
  },
});
