//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AccordionSection from "../sections/AccordionSection.vue";
import Advantages from "../sections/Advantages.vue";
import FirstScreen from "../sections/FirstScreen.vue";
import ImageAndText from "../sections/ImageAndText.vue";
import ImageSection from "../sections/ImageSection.vue";
import ImageSlider from "../sections/ImageSlider.vue";
import ImagesThree from "../sections/ImagesThree.vue";
import NumbersSection from "../sections/NumbersSection.vue";
import QuoteSection from "../sections/quoteSection.vue";
import SimpleText from "../sections/SimpleText.vue";
import Stages from "../sections/Stages.vue";
import ColText from "../sections/ColText.vue";
import VideoText from "../sections/VideoText.vue";
import Contacts from "../sections/Contacts.vue";
import Ticker from "../sections/Ticker.vue";
import CardsLanding from "../sections/CardsLanding.vue";
import TextNextColBtn from "../sections/textNextColBtn.vue";
import QuoteSliderSection from "../sections/quoteSliderSection.vue";
import TableComponent from "../sections/TableComponent.vue";
import FormWidget from './FormWidget.vue';
import { mapGetters } from 'vuex';
import FirstScreenLanding from '../sections/FirstScreen-landing.vue';
import SeeAlso from '../ArticleParts/SeeAlso.vue';
import Cta from './Cta.vue';
export default {
  name: "sectionComponent",
  props: {
    main_screen: {
        type: Object,
        default: () => {}
    },
  },
  components: {
    SimpleText,
    "text-n-columns": ColText,
    ImageAndText,
    "video-and-text": VideoText,
    "images-3": ImagesThree,
    Stages,

    quotes: QuoteSection,
    "full-image": ImageSection,
    Advantages,
    accordion: AccordionSection,
    numbers: NumbersSection,
    gallery: ImageSlider,
    ticker: Ticker,
    contactsComponent: Contacts,
    FirstScreen,
    blocks: CardsLanding,
    "simple-text-btn-left": TextNextColBtn,
    "quote-slider": QuoteSliderSection,
    table_component: TableComponent,
    "form_component": FormWidget,
    FirstScreenLanding,
    SeeAlso,
    "cta": Cta,
  },
  computed: {
    ...mapGetters(["getMenuState"])
  },
};
