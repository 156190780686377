//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            count: 1
        }
    },
  props: {
    messages: {
      type: Object,
      default: () => {},
    },
    inputData: {
      type: Object,
      default: () => {},
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    numbPlus() {
        this.count++
        this.$emit('input', +this.$refs.numb.value + 1)
    },
    numbMinus() {
        if ( this.count > 1 ) {
            this.count--
        } else {
            this.count = 1
        }
        this.$emit('input', +this.$refs.numb.value + 1)
    }
  },
  computed: {
    errorText() {
      if (!this.inputData.email) {
        return this.messages.email;
      }
      if (!this.inputData.required) {
        return this.messages.required;
      }
      if (!this.inputData.min) {
        return this.messages.min;
      }
      if (!this.inputData.max) {
        return this.messages.max;
      }
      return null;
    },
  },
};
